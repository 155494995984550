export const cardInfoField = {
  computed: {},

  methods: {
    infoFieldChanged(field) {
      return this.$api.card.updateCardInfoField({
        card_id: this.card.id,
        name: field,
        value: this.card.info[field],
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Карта успешно сохранена'
          });
        })
        .finally(() => {

        })
    }
  }
}